$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';
@import '../../scss/shared/_variables';

$dropdown-transition: opacity 0.4s ease;

.wrapper {
    @include sassy-font-body($size: small);
    display: flex;
    color: $sassy-color-noir;
    position: static;
}

.dropdown {
    visibility: visible;
    opacity: 1;
    &:not(.isVisible) {
        /*
        remove from accessibiliy tree so that items in dropdown are only usable
        when mouse events trigger the dropdown.
        */
        visibility: hidden;
        pointer-events: none;
    }
}

.isVisible {
    visibility: visible;
    opacity: 1;
}

.bodyWrapper {
    height: $global-nav-height;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 1px); // 1px to show Drawer's border-bottom
    background: $sassy-color-white;
    transition: $dropdown-transition;
    // delay fading-out of visible dropdown so that once transition
    // begins, it overlaps text from dropdown fading-in
    transition-delay: 0.2s;
    width: 100%;
    @include dbl-header-shadow(0.16);
    @include sassy-z-index(nav);
    &.isVisible {
        height: auto;
        min-height: $global-nav-height;
        opacity: 1;
        overflow: visible;
        // no delay on fading-in prevents nav from being
        // completely transparent during opacity transition
        // and allows for overlap with fading-out dropdown text
        transition-delay: 0s;
    }
}
