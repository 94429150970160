$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_hidden';

.label {
    @include sassy-font-body($type: heavy, $size: small);
    color: $sassy-color-noir;
    margin-bottom: $sassy-spacing-x-small;
    text-align: left;
    display: block;
}
