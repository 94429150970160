@import '~dibs-sassy/exports/_breakpoints';
@import './_variables';

@mixin dibs-width() {
    // $dbl-width-fallback is coming from _variables,
    // the value is required for fallback if the custom
    // variable in $dbl-width is not supported by browser
    width: $dbl-width-fallback;
    width: $dbl-width; // _variables
    max-width: $dbl-max-width; // variables
}

@mixin primary-page-width() {
    @include dibs-width;
    margin: 0 auto;
}

@mixin primary-viewport-width() {
    // use this mixin if your element needs to
    // break outside of the #mainContent width
    // at a certain breakpoint
    // if the breakpoint is irrelevant, it is
    // preferable to use the `.primary-viewport-width`
    // global class instead
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
}

// primary-viewport-width doesn't handle non-ipad viewport widths less than
// 1024px because the viewport is less than the min-width applied to the page.
// the viewport and min-width mismatch means 100vw != min-width size. styles
// relying on 100vw (left and width in primary-viewport-width) do not function
// as expected and are not flush with other hp modules.
@mixin primary-viewport-width-tablet-fix() {
    overflow: hidden;
    @include primary-viewport-width;
    @include sassy-breakpoint-between(tablet-portrait, tablet-landscape) {
        position: relative;
        left: calc(-1 * var(--sassy-layout-offset));
        min-width: $dbl-responsive-min-width;
        width: 100vw;
    }
}
