$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.container {
    @include sassy-font-body(small);
    background: $sassy-color-buyer-tertiary;
    padding: $sassy-spacing-small;
    color: $sassy-color-noir;
    position: relative;
}

.heading {
    @include sassy-font-body($size: small, $type: heavy);
    display: inline-flex;
}

.goldHeading {
    @include sassy-font-sub-header($size: small);
    color: $sassy-color-buyer-primary;
    padding-right: $sassy-spacing-x-small;
}

.bodyText {
    padding-top: $sassy-spacing-x-small;
    padding-bottom: $sassy-spacing-x-small;
    display: block;
}

.promptBody {
    display: inline-flex;
}

.promptText {
    padding-top: 4px;
    display: inline-flex;
    &:hover {
        color: $sassy-color-buyer-primary;
    }
}

.switch {
    padding-left: $sassy-spacing-x-small;
}

.savingMessage {
    @include sassy-font-body(x-small);
    padding-right: $sassy-spacing-small;
    text-align: right;
    color: $sassy-color-iron;
    height: 14px;
}

.subText {
    color: $sassy-color-iron;
}

.sectionDivider {
    padding: 1px;
    background-color: $sassy-color-dolphin;
}
