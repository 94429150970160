@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_breakpoints';
@import '../../scss/shared/_headerVariables';

.bannerContainer {
    @include sassy-z-index(middle);
    display: flex;
    align-items: center;
    background: $sassy-color-noir;
    min-height: $message-banner-height;
    height: 0; // IE fix related to align-items: center and min-height
    position: relative;
    overflow: hidden;

    @include sassy-breakpoint-below(mobile) {
        min-height: $mobile-message-banner-height;
        width: 100%;
        height: auto;
    }
}

.unsetMobileHeight {
    @include sassy-breakpoint-below(mobile) {
        min-height: unset;
    }
}
