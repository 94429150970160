$__INTERNAL_USE_ONLY_BODY_BACKUPS__: 'Helvetica Neue', 'helvetica', 'arial', 'sans-serif';
$__INTERNAL_USE_ONLY_HEADER_BACKUPS__: 'Georgia', 'serif';
$__INTERNAL_USE_ONLY_FONT_BODY__: 'proxima-nova', $__INTERNAL_USE_ONLY_BODY_BACKUPS__;
$__INTERNAL_USE_ONLY_FONT_HEADER__: 'Cardinal Classic Short', $__INTERNAL_USE_ONLY_HEADER_BACKUPS__;

$__INTERNAL_USE_ONLY_BODY_SIZE__: (
    none: null,
    xx-small: 10px,
    x-small: 12px,
    small: 14px,
    medium: 16px,
    large: 18px,
    header-large: 24px,
    header-xx-large: 32px,
);

$__INTERNAL_USE_ONLY_SUB_HEADER_SIZE__: (
    none: null,
    small: 12px,
    medium: 14px,
);

$__INTERNAL_USE_ONLY_HEADER_SIZE__: (
    none: null,
    x-small: 14px,
    small: 16px,
    medium: 20px,
    large: 24px,
    x-large: 28px,
    xx-large: 32px,
    vin-diesel: 52px,
    xxxx-large: 80px,
);
$__INTERNAL_USE_ONLY_HEADER_LINE_HEIGHT__: (
    none: null,
    small: 1.4,
    medium: 1.4,
    large: 1.4,
    x-large: 1.4,
    xx-large: 1.4,
    vin-diesel: 1.4,
    xxxx-large: 1.1,
);
$__INTERNAL_USE_ONLY_HEADER_NORMAL_LETTER_SPACING__: (
    none: null,
    small: -0.5px,
    medium: -0.5px,
    large: -0.5px,
    x-large: -0.5px,
    xx-large: -0.5px,
    vin-diesel: -1px,
    xxxx-large: -2px,
);

$__INTERNAL_USE_ONLY_BODY_TYPE__: (
    none: null,
    normal: $__INTERNAL_USE_ONLY_FONT_BODY__,
    heavy: $__INTERNAL_USE_ONLY_FONT_BODY__,
    italic: $__INTERNAL_USE_ONLY_FONT_BODY__,
);

$__INTERNAL_USE_ONLY_SUB_HEADER_TYPE__: (
    none: null,
    normal: $__INTERNAL_USE_ONLY_FONT_BODY__,
    heavy: $__INTERNAL_USE_ONLY_FONT_BODY__,
);

$__INTERNAL_USE_ONLY_HEADER_TYPE: (
    none: null,
    normal: $__INTERNAL_USE_ONLY_FONT_HEADER__,
    italic: $__INTERNAL_USE_ONLY_FONT_HEADER__,
);

@mixin __INTERNAL_USE_ONLY_FONT_ERROR__($message) {
    @error "dibs-sassy | _fonts.scss | mixin #{$message}. Please see documentation at https://adminv2.1stdibs.com/internal/style-guide.";
}

@mixin sassy-font-body($size: medium, $type: normal) {
    $body-type: $__INTERNAL_USE_ONLY_BODY_TYPE__;
    $body-size: $__INTERNAL_USE_ONLY_BODY_SIZE__;

    @if map-has-key($body-type, $type) {
        font-family: map-get($body-type, $type);
    } @else {
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-body' does not accept type '#{$type}'"
        );
    }
    @if map-has-key($body-size, $size) {
        font-size: map-get($body-size, $size);
    } @else {
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-body' does not accept size '#{$size}'"
        );
    }
    @if $type == 'heavy' {
        font-weight: 600;
    } @else if $type == 'italic' {
        font-style: italic;
        font-weight: 300;
    } @else {
        font-weight: 300;
    }
}

@mixin sassy-font-sub-header($size: medium, $type: heavy) {
    $sub-header-type: $__INTERNAL_USE_ONLY_SUB_HEADER_TYPE__;
    $sub-header-size: $__INTERNAL_USE_ONLY_SUB_HEADER_SIZE__;

    @if map-has-key($sub-header-type, $type) {
        font-family: map-get($sub-header-type, $type);
    } @else {
        font-family: map-get($sub-header-type, normal);
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-sub-header' does not accept type '#{$type}'"
        );
    }
    @if map-has-key($sub-header-size, $size) {
        font-size: map-get($sub-header-size, $size);
    } @else {
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-sub-header' does not accept size '#{$size}'"
        );
    }
    @if $type != none {
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    @if $type == 'heavy' {
        font-weight: 600;
    } @else {
        font-weight: 300;
    }
}

@mixin sassy-font-header($size: large, $type: normal) {
    $header-type: $__INTERNAL_USE_ONLY_HEADER_TYPE;
    $header-size: $__INTERNAL_USE_ONLY_HEADER_SIZE__;
    $header-line-height: $__INTERNAL_USE_ONLY_HEADER_LINE_HEIGHT__;
    $header-normal-letter-spacing: $__INTERNAL_USE_ONLY_HEADER_NORMAL_LETTER_SPACING__;

    @if map-has-key($header-type, $type) {
        font-family: map-get($header-type, $type);
        font-style: $type;
        font-weight: normal;
    } @else {
        font-family: map-get($header-type, normal);
        font-style: normal;
        font-weight: normal;
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-header' does not accept type '#{$type}'"
        );
    }
    @if map-has-key($header-size, $size) {
        font-size: map-get($header-size, $size);
        line-height: map-get($header-line-height, $size);
    } @else {
        @include __INTERNAL_USE_ONLY_FONT_ERROR__(
            "'sassy-font-header' does not accept size '#{$size}'"
        );
    }
    @if $type == 'normal' and map-has-key($header-normal-letter-spacing, $size) {
        letter-spacing: map-get($header-normal-letter-spacing, $size);
    } @else if $type == 'italic' {
        letter-spacing: 0;
    }
}
