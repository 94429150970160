$user-type: 'buyer';
@import '../../scss/shared/_alerts';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';

.iconWrapper {
    display: flex;
    align-items: center;
}

.icon {
    cursor: pointer;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin: 0 $sassy-spacing-x-small;
    position: relative;
    @include headerLink;
}

.hasUnread {
    @include alert-unread(-4px);
}
