@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_buyerLinks';

@import './_layout';

@mixin headerBar {
    background: $sassy-color-white;
    display: flex;
    justify-content: center;
    position: relative;
}

@mixin headerBarContent {
    @include dibs-width;
    position: static;
    align-items: center;
}

@mixin headerLink {
    @include sassy-buyer-standard-link-no-effect($underline: none);
}

@mixin dropdownBodyWrapperPosition {
    top: 100%;
}

@mixin dropdownArrowPosition {
    &:before {
        top: calc(100% - #{$sassy-spacing-x-small} - 1px);
        @include sassy-z-index(above-nav);
    }
}

@mixin responsive-nav-links {
    //used to prevent navigation links from overlapping contact1stDibs link on smaller (<1200px) responsive devices
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin dbl-header-shadow($opacity) {
    box-shadow: 0px 2px 8px 0 rgba($sassy-color-pitch-black, $opacity);
}
