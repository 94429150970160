// Do not modify, this file was generated
// to modify, reference the files in dibs-sassy/src/spacing/spacing.json

// Spacing variables
$sassy-spacing-xx-small: 5px;
$sassy-spacing-x-small: 9px;
$sassy-spacing-smaller: 14px;
$sassy-spacing-small: 18px;
$sassy-spacing-medium: 27px;
$sassy-spacing-large: 36px;
$sassy-spacing-larger: 45px;
$sassy-spacing-x-large: 54px;
$sassy-spacing-xx-large: 72px;
