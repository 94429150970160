$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/_headerVariables';
@import '~dibs-sassy/exports/_buyerLinks';

.accountIcon {
    cursor: pointer;
    display: inline-block;
    margin: 0 $sassy-spacing-x-small 0 $sassy-spacing-small;
    position: relative;
    height: 18px;
    width: 18px;
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.accountWrapper {
    @include sassy-buyer-standard-link-no-effect($underline: none);
    align-items: center;
    display: flex;
    cursor: pointer;
    height: 100%;
}

.maskIcon {
    color: inherit;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    margin-left: $sassy-spacing-x-small;
    width: 15px;
}
.maskDirectLoginIcon {
    position: relative;
    top: -2px;
    width: 25px;
    height: 25px;
    margin-right: $sassy-spacing-x-small;
}

.nameWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 32px;
}

.name {
    @include sassy-buyer-standard-link($underline: none);
    @include sassy-font-sub-header($size: small, $type: normal);
    color: inherit;
    transition: color 0.4s ease;
    display: flex;
    height: 16px;
    padding-top: 1px;
    line-height: 16px;

    &:hover {
        color: $sassy-color-noir;
    }
}

.nameTrade {
    composes: name;
    @include sassy-font-body($size: small, $type: heavy);
    padding-top: 4px;
}

.tradeStatus,
.tradePoints {
    margin-top: $sassy-spacing-x-small;
    @include sassy-font-sub-header($size: small, $type: normal);
    @include sassy-buyer-standard-link($underline: none);
    line-height: 16px;

    &:hover {
        color: $sassy-color-noir;
    }
}

.tradePoints {
    &::after {
        content: '|';
        padding: 0 $sassy-spacing-x-small;
        color: $sassy-color-noir;
    }
}
