$user-type: 'buyer';

// common variables
@import '../Common/Button/_buttonVariables';

@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$clear-icon-size-small: 14px;
$clear-icon-size-medium: 18px;
$clear-icon-size-large: 22px;

$search-icon-size-small: 18px;
$search-icon-size-medium: 22px;
$search-icon-size-large: 26px;

@mixin square($buttonSize, $iconSize) {
    $actualSize: $button-size-large;
    @include sassy-font-body($size: medium, $type: none);
    @if $buttonSize == 'small' {
        @include sassy-font-body($size: x-small, $type: none);
        $actualSize: $button-size-small;
    } @else if $buttonSize == 'medium' {
        @include sassy-font-body($size: small, $type: none);
        $actualSize: $button-size-medium;
    }
    padding: ($actualSize - $iconSize) * 0.5;
    height: $actualSize;
    width: $actualSize;
    overflow: hidden;
}

:local {
    .wrapper {
        display: flex;
    }

    .decoratorWrapper {
        padding: 0 !important;
    }

    .defaultSearch {
        @if $user-type == 'buyer' {
            @include square('large', $search-icon-size-large);
        } @else if $user-type == 'dealer' {
            @include square('medium', $search-icon-size-medium);
        } @else {
            @include square('small', $search-icon-size-small);
        }
    }

    .smallSearch {
        @include square('small', $search-icon-size-small);
    }

    .mediumSearch {
        @include square('medium', $search-icon-size-medium);
    }

    .largeSearch {
        @include square('large', $search-icon-size-large);
    }

    .defaultButton {
        // the clear button somehow sits below the input and the search button,
        // which blocks the focus ring. bump it to prevent this.
        @include sassy-z-index(bump);
        @if $user-type == 'buyer' {
            @include square('large', $clear-icon-size-large);
        } @else if $user-type == 'dealer' {
            @include square('medium', $clear-icon-size-medium);
        } @else {
            @include square('small', $clear-icon-size-small);
        }
    }

    .smallButton {
        @include square('small', $clear-icon-size-small);
    }

    .mediumButton {
        @include square('medium', $clear-icon-size-medium);
    }

    .largeButton {
        @include square('large', $clear-icon-size-large);
    }

    .fullyTransparent {
        background-color: transparent;
        color: $sassy-color-noir;
        @if $user-type == 'dealer' {
            color: $sassy-color-dealer-primary;
        }
    }

    .whiteFill {
        fill: $sassy-color-white;
    }

    .hideButton {
        opacity: 0;
        width: 0;
        padding: 0;
        transition: opacity $button-transition-time linear, width 0ms linear $button-transition-time,
            padding 0ms linear $button-transition-time !important;
    }

    .showButton {
        opacity: 1;
        transition: opacity $button-transition-time linear;
    }

    .shiftRight {
        margin-right: -1px;
    }

    .noClick {
        pointer-events: none;
    }

    .hideDropdown {
        display: none;
        opacity: 0;
    }
}

.headerSearchButton {
    padding: 10px;
    color: $sassy-color-pitch-black;
}

.headerSearchClearButton {
    padding: 13px;
    color: $sassy-color-pitch-black;
}

.openDropdown {
    border: 1px solid $sassy-color-pitch-black;
    flex: 1;
}
