$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/_headerVariables';

$dropdown-triangle-border-width: 10px;
$dropdown-transition: opacity 0.2s ease, visibility 0.2s ease;
$dropdown-header-color: $sassy-color-moonstone;

.wrapper {
    @include sassy-font-body($size: small);
    color: $sassy-color-noir;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.dropdown {
    opacity: 0;
    transition: $dropdown-transition;
    visibility: hidden;

    &.hasDropdownHeader {
        &::before {
            border-bottom: $dropdown-triangle-border-width solid $dropdown-header-color;
        }
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: 0;
        border-left: $dropdown-triangle-border-width solid transparent;
        border-right: $dropdown-triangle-border-width solid transparent;
        border-bottom: $dropdown-triangle-border-width solid $sassy-color-white;
        top: calc(100% - #{$dropdown-triangle-border-width});
        left: calc(50% - #{$dropdown-triangle-border-width});
        transition: $dropdown-transition;
        @include sassy-z-index(middle);
    }
}

.isVisible {
    visibility: visible;
    opacity: 1;
}

.bodyWrapper {
    @include sassy-box-shadow;
    @include sassy-z-index(nav);
    background: $sassy-color-white;
    position: absolute;
    top: 100%;

    &.small {
        width: 250px;
    }

    &.medium {
        width: 350px;
    }

    &.large {
        width: 450px;
    }

    &.extraLarge {
        width: 550px;
    }
}

.body {
    position: relative;
    overflow-y: scroll;
    max-height: 600px;
}

.header {
    display: flex;
    padding: $sassy-spacing-x-small $sassy-spacing-small;
    justify-content: space-between;
    background-color: $dropdown-header-color;
}

.headerTitle {
    @include sassy-font-sub-header($type: normal);
}

.headerLink {
    font-size: 13px;
}
