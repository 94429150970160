$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_breakpoints';

.wrapper {
    display: flex;
}

.iconWrapper {
    @include sassy-buyer-standard-link-no-effect($underline: none);
    display: inline-block;
    line-height: 0;
    max-height: 45px;
    width: 130px;
    text-align: center;
    transition: 0.3s all ease;
    @include sassy-breakpoint-below(tablet-portrait) {
        width: 120px;
    }
}

.stickyLogo {
    width: 88px;
}
