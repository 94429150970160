$user-type: 'buyer';
@import '../../scss/shared/_headerVariables';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';

@keyframes slideOut {
    0% {
        transform: translateY(0%);
    }

    10% {
        border-bottom-width: 0;
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

.slideIn {
    animation: slideIn 0.3s;
}

.slideOut {
    animation: slideOut 0.3s forwards;
    @include sassy-z-index(below);
}

.drawer {
    position: relative;
    border-bottom: 1px solid $sassy-color-dolphin;
}
