$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';

.bar {
    @include headerBar();
    height: $bottom-bar-height;
}

.barContent {
    @include headerBarContent();
}

.wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;
}
