$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_breakpoints';
@import '../../scss/shared/_variables';
@import './MessageBannerLazy';

$banner-arrow-margin: 2%;
$banner-color-gold: $sassy-color-buyer-primary;

// references class used in packages/dibs-buyer-layout/src/entries/footer.tsx
:global(.footer-message-banner) {
    @include sassy-z-index(high);
    bottom: 0;
    position: sticky;
}

.carouselWrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: $dbl-max-width;
    padding: 0 $sassy-spacing-large;
    width: 100%;

    &.hasPagination {
        justify-content: space-between;

        @include sassy-breakpoint-below(mobile) {
            padding: 0 $sassy-spacing-x-small;
        }
    }
}

.carousel {
    overflow: hidden;
    width: 100%;
}

.carouselItems {
    display: flex;
    align-items: center;
    position: relative;
    transition: transform 250ms linear;

    &.noTransition {
        transition: none;
    }
}

.carouselArrowWrapperLeft {
    margin: auto;
    margin-right: $banner-arrow-margin;
}

.carouselArrowWrapperRight {
    margin: auto;
    margin-left: $banner-arrow-margin;
}

.carouselArrowLeft,
.carouselArrowRight {
    cursor: pointer;
    width: 19px;
    height: 19px;
    padding-top: 4px;
    fill: $banner-color-gold;
    stroke: $banner-color-gold;
    stroke-width: 3px;
}

.itemWrapper {
    @include sassy-font-body($size: small);
    color: $sassy-color-white;
    flex: 0 1 100%;
    text-align: center;

    @include sassy-breakpoint-above(tablet-portrait) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @include sassy-breakpoint-below(mobile) {
        line-height: 1.3;
        padding: $sassy-spacing-x-small 0;
    }
}

.itemContent {
    color: $sassy-color-white;
}

.ctaLink {
    @include sassy-font-body($size: small);
    color: $sassy-color-white;
}

.closeIconWrapper {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    color: $sassy-color-white;

    @include sassy-breakpoint-below(mobile) {
        width: 18px;
        height: 18px;
        right: 10px;
    }
}

.closeIcon {
    fill: $sassy-color-white;
}
