// Do not modify, this file was generated
// to modify, reference the files in dibs-sassy/src/zIndex/zIndex.json

@mixin sassy-z-index($type, $isImportant: false) {
    $zIndexMap: (
        far-below: -100,
        below: -10,
        none: 0,
        bump: 10,
        low: 100,
        middle: 200,
        high: 300,
        below-nav: 960,
        nav: 980,
        above-nav: 990,
        overlay: 10020,
        above-overlay: 10030,
    );

    @if map-has-key($zIndexMap, $type) {
        @if $isImportant == isImportant {
            z-index: map-get($zIndexMap, $type) !important;
        } @else {
            z-index: map-get($zIndexMap, $type);
        }
    } @else {
        @error "dibs-sassy | _zindex.scss | mixin 'sassy-z-index' does not accept type '#{$type}'. Please see documentation at https://adminv2.qa.1stdibs.com/style-guide.";
    }
}
