@import '~dibs-sassy/exports/_colors';

@mixin alert-unread($right: -1px) {
    &:after {
        display: block;
        content: '';
        position: absolute;
        right: $right;
        top: -1px;
        width: 9px;
        height: 9px;
        border-radius: 999px;
        background: $sassy-color-alert-primary;
    }
}
