$user-type: 'buyer';

// common styles
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_focus';
@import '~dibs-sassy/exports/_zindex';

// common variables
@import './_buttonVariables';

@mixin buttonColor($primaryColor, $hoverColor) {
    color: $sassy-color-white;
    background-color: $primaryColor;
    &:hover:not(.disabled) {
        color: $sassy-color-white;
        background-color: $hoverColor;
    }
}

@mixin afterBoxShadow() {
    $boxShadowColor: $sassy-color-buyer-secondary;
    @if $user-type == 'internal' {
        $boxShadowColor: $sassy-color-internal-secondary;
    } @else if $user-type == 'dealer' {
        $boxShadowColor: $sassy-color-dealer-secondary;
    }
    // if using box-shadow, outline is not needed
    outline: none;

    //::after allows for greater control of style because it permits use of all CSS properties
    &::after {
        // nothing should hide focus. if an element has focus and is under an overlay,
        // a focus-trap or other programmatic focus control should be implemented
        @include sassy-z-index(above-overlay);
        content: '';
        border-radius: 2px;
        // parent must have position: <absolute | relative>
        // parent must not have overflow: hidden
        position: absolute;
        box-shadow: 0 0 2px 2px $boxShadowColor;
        left: -1px;
        right: -1px;
        bottom: -1px;
        top: -1px;
    }
}

@mixin focusVisibleBoxShadow() {
    // needed for ::after position: absolute. if a button has a custom class, it
    // should be applied after this style and with the same or greater specificity,
    // allowing for .button { position: absolute; }, which also works with the
    //::after content. all of this should happen by default based on the order CSS
    // loads
    position: relative;

    &:focus-visible {
        @include afterBoxShadow();
    }
    &:global(.focus-visible) {
        // prevent cssnano from merging .focus-visible and :focus-visible, which is
        // currently only supported in firefox
        outline: none;
        @include afterBoxShadow();
    }
}

:local {
    .button {
        @include sassy-font-sub-header($size: none, $type: normal);
        border: 0;
        border-radius: 0;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        user-select: none;
        white-space: nowrap;
        box-shadow: none;
        box-sizing: border-box;
        transition: all $button-transition-time linear;

        @include focusVisibleBoxShadow();

        &.disabled {
            background-color: $sassy-color-dolphin;
            color: $sassy-color-white;
            border: 0;
            cursor: not-allowed;
        }
    }

    .primaryButton {
        @if $user-type == 'internal' {
            @include buttonColor($sassy-color-internal-primary, $sassy-color-internal-secondary);
        } @else if $user-type == 'dealer' {
            @include buttonColor($sassy-color-dealer-primary, $sassy-color-dealer-secondary);
        } @else {
            @include buttonColor($sassy-color-noir, $sassy-color-charcoal);
        }
    }
}
