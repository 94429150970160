// Do not modify, this file was generated
// to modify, reference the files in dibs-sassy/src/colors/*

// Alert Colors
$sassy-color-alert-primary: #cc0000;
$sassy-color-alert-secondary: #950808;
$sassy-color-alert-tertiary: #fedcde;
$sassy-color-warning-primary: #f07f04;
$sassy-color-warning-secondary: #ffc100;
$sassy-color-success-primary: #66a559;
$sassy-color-success-secondary: #2a7140;
$sassy-color-success-tertiary: #aedfa3;

// Buyer Colors
$sassy-color-buyer-primary: #c2a661;
$sassy-color-buyer-secondary: #a48e45;
$sassy-color-buyer-tertiary: #f6f3eb;
$sassy-color-buyer-tertiary-alt: #e8dfcd;
$sassy-color-buyer-primary-iphone: #cca738;

// Dealer Colors
$sassy-color-dealer-primary: #436b93;
$sassy-color-dealer-secondary: #204664;
$sassy-color-dealer-tertiary: #dee3eb;
$sassy-color-dealer-tertiary-alt: #d8ecf5;

// General Colors
$sassy-color-blue-50: #eef1f7;
$sassy-color-blue-400: #819cc5;
$sassy-color-blue-700: #375d81;
$sassy-color-blue-800: #204664;
$sassy-color-blue-900: #1b3b59;
$sassy-color-blue-950: #193148;
$sassy-color-green-50: #eff9ea;
$sassy-color-green-100: #e5f4de;
$sassy-color-green-200: #ceecc1;
$sassy-color-green-700: #418f48;
$sassy-color-green-900: #1b5633;
$sassy-color-green-950: #0e3c1e;
$sassy-color-red-50: #fef2f2;
$sassy-color-red-200: #fecaca;
$sassy-color-red-700: #cc0000;
$sassy-color-red-900: #770303;
$sassy-color-shagreen-50: #edf3f3;
$sassy-color-shagreen-200: #cbdbdb;
$sassy-color-shagreen-300: #bacecc;
$sassy-color-shagreen-400: #aabeb9;
$sassy-color-shagreen-600: #73919b;
$sassy-color-shagreen-700: #517884;
$sassy-color-shagreen-800: #2e5f6c;
$sassy-color-shagreen-950: #223f4c;
$sassy-color-tangerine-500: #f9902d;
$sassy-color-tangerine-700: #d26a00;
$sassy-color-yellow-50: #fff4e0;
$sassy-color-yellow-100: #ffe9b6;
$sassy-color-yellow-200: #ffdf8b;
$sassy-color-yellow-300: #ffd660;
$sassy-color-yellow-700: #be9116;
$sassy-color-yellow-950: #5d4a20;
$sassy-color-gray-500: #888888;
$sassy-color-gray-700: #555555;
$sassy-color-cyan-50: #e8f4f9;
$sassy-color-cyan-200: #c6e6f1;
$sassy-color-cyan-600: #2683a3;

// Grayscale Colors
$sassy-color-white: #fff;
$sassy-color-alabaster: #fbfbfb;
$sassy-color-moonstone: #f3f3f3;
$sassy-color-dolphin: #ddd;
$sassy-color-storm: #bbb;
$sassy-color-iron: #888;
$sassy-color-satan: #666;
$sassy-color-charcoal: #444;
$sassy-color-noir: #222;
$sassy-color-pitch-black: #000;
$sassy-color-overlay-background: rgba(0, 0, 0, 0.7);
$sassy-color-overlay-light: rgba(255, 255, 255, 0.4);

// Internal Colors
$sassy-color-internal-primary: #559b5e;
$sassy-color-internal-secondary: #2a7140;
$sassy-color-internal-tertiary: #b8e7c8;

// Marketing Colors
$sassy-color-marketing-sale: #950808;
$sassy-color-nft-wine: #610034;

// Medal Colors
$sassy-color-medal-bronze: #a06931;
$sassy-color-medal-silver: #637583;
$sassy-color-medal-gold: #8c7236;
$sassy-color-medal-platinum: #727383;

// Third Party Colors
$sassy-color-pinterest-primary: #e00002;
$sassy-color-facebook-primary: #3b5998;
$sassy-color-instagram-primary: #275a85;
$sassy-color-linkedin-primary: #007bb6;

// Trade Colors
$sassy-color-trade-primary: #0f8d88;
$sassy-color-trade-secondary: #0d7d77;
$sassy-color-trade-tertiary: #e7f4f3;
$sassy-color-trade-member: #666;

// color mixins
@mixin sassy-box-shadow() {
    box-shadow: 1px 2px 8px 0 rgba($sassy-color-noir, 0.25);
}

@mixin __INTERNAL_USE_ONLY_OPACITY_ERROR__($message) {
    @error "dibs-sassy | colors/_mixins.scss | mixin #{$message}. Please see documentation at https://adminv2.1stdibs.com/internal/style-guide.";
}

@mixin sassy-background-opacity($color, $opacity) {
    $bg-colors: (
        white: $sassy-color-white,
        sassy-color-white: $sassy-color-white,
        moonstone: $sassy-color-moonstone,
        sassy-color-moonstone: $sassy-color-moonstone,
        noir: $sassy-color-noir,
        sassy-color-noir: $sassy-color-noir,
    );
    $opacity-values: (
        high: 0.9,
        medium: 0.6,
    );
    $bg-color: $sassy-color-white !default;
    $bg-opacity: 0.9 !default;

    @if map-has-key($bg-colors, $color) {
        $bg-color: map-get($bg-colors, $color);
    } @else {
        $keys: map-keys($bg-colors);
        @include __INTERNAL_USE_ONLY_OPACITY_ERROR__('$color must be one of: #{$keys}');
    }

    @if map-has-key($opacity-values, $opacity) {
        $bg-opacity: map-get($opacity-values, $opacity);
    } @else {
        $keys: map-keys($opacity-values);
        @include __INTERNAL_USE_ONLY_OPACITY_ERROR__('$opacity must be one of: #{$keys}');
    }

    background-color: rgba($bg-color, $bg-opacity);
}
