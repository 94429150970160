$user-type: 'buyer';
@import '../../scss/shared/_headerMixins';

.likesWrapper {
    position: relative;
    height: 100%;
}

.noHover {
    pointer-events: none;
}

.dropdownBodyWrapper {
    @include dropdownBodyWrapperPosition();
}

.dropdown {
    @include dropdownArrowPosition();
}
