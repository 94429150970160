$user-type: 'buyer';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';
@import '~dibs-sassy/exports/_zindex';

.bar {
    @include headerBar();
    @include dbl-header-shadow(0);
    @include sassy-z-index(bump);
    height: $top-bar-height;
    /*
      Element(s) that will transform start out rendered in 3D so browsers--I'm looking at your Safari--don't have to
      switch in and out of rendering modes causing DOM elements to flicker
      http://stackoverflow.com/a/8948115
    */
    -webkit-transform: translateZ(0);
}

.stickyBar {
    @include dbl-header-shadow(0.08);
    height: $sticky-top-bar-height;
    transition: all 0.3s ease;
}

.barContent {
    @include headerBarContent();
    display: flex;
    justify-content: space-between;
}

.leftContainer {
    display: flex;
    align-items: center;
    flex: 1;
}

.rightContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: $top-bar-icons-hover-area-height;
}
