$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_breakpoints';
@import '../../scss/shared/_headerVariables';

$search-bar-width: 300px;
$active-search-bar-width: 400px;

.form {
    display: flex;
    margin: 0;
    padding: 0;
}

.boldTerm {
    @include sassy-font-body($type: heavy);
}

.wrapper {
    position: relative;
    @include sassy-z-index(none);
}

.searchBar {
    width: $search-bar-width;
    transition: all 0.2s ease;

    &.isActive {
        width: $active-search-bar-width;
    }
}

.overlayWrapper {
    position: absolute;
    width: 100%;
    margin-top: -1px;
    @include sassy-z-index(below);
}

.overlay {
    @include sassy-box-shadow;
    background: $sassy-color-white;
    display: none;
    width: 100%;
    &.isVisible {
        display: block;
        border-top: 2px solid $sassy-color-dolphin;
    }

    @include sassy-breakpoint-above(tablet-portrait) {
        border: 1px solid $sassy-color-noir;
        border-top: 0;
    }
}

.boldTerm {
    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: large, $type: heavy);
    }
}
