@import '../exports/_colors';

@mixin __INTERNAL_USE_ONLY_BASE_LINK($color, $hoverColor, $underline) {
    color: $color;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
        color: $hoverColor;
    }

    @if $underline != none {
        text-decoration: underline;
    } @else {
        text-decoration: $underline;
    }

    @include sassy-buyer-link-touch-color($color);
}

@mixin sassy-buyer-standard-link($underline: underline) {
    @include __INTERNAL_USE_ONLY_BASE_LINK(
        $color: $sassy-color-pitch-black,
        $hoverColor: $sassy-color-charcoal,
        $underline: $underline
    );
}

@mixin sassy-buyer-inverted-link($underline: null) {
    $hoverTextDecoration: underline;
    $textDecoration: none;
    @if $underline {
        $textDecoration: $underline;
        $hoverTextDecoration: $underline;
    }

    color: $sassy-color-charcoal;
    cursor: pointer;
    transition: color 0.2s ease;
    text-decoration: $textDecoration;
    &:hover {
        color: $sassy-color-charcoal;
        text-decoration: $hoverTextDecoration;
    }
}

@mixin sassy-buyer-contrast-link-light-inverse($underline: null) {
    $hoverTextDecoration: underline;
    $textDecoration: none;
    @if $underline {
        $textDecoration: $underline;
        $hoverTextDecoration: $underline;
    }

    text-decoration: $textDecoration;
    color: $sassy-color-white;
    &:hover {
        color: $sassy-color-white;
        text-decoration: $hoverTextDecoration;
    }
}

@mixin sassy-buyer-contrast-link-light($underline: null) {
    $hoverTextDecoration: underline;
    $textDecoration: none;
    @if $underline {
        $textDecoration: $underline;
        $hoverTextDecoration: $underline;
    }

    text-decoration: $textDecoration;
    color: $sassy-color-white;
    a:hover,
    &:hover {
        color: $sassy-color-white;
        text-decoration: $hoverTextDecoration;
    }
}

@mixin sassy-buyer-contrast-link-alternate($underline: null) {
    $hoverTextDecoration: underline;
    $textDecoration: none;
    @if $underline {
        $textDecoration: $underline;
        $hoverTextDecoration: $underline;
    }

    text-decoration: $textDecoration;
    color: $sassy-color-storm;
    &:hover {
        color: $sassy-color-storm;
        text-decoration: $hoverTextDecoration;
    }
}

@mixin sassy-buyer-contrast-link-no-effect($underline: none) {
    text-decoration: $underline;
    color: $sassy-color-white;
    &:hover {
        color: $sassy-color-white;
    }
}

@mixin sassy-buyer-standard-link-no-effect($underline: none) {
    text-decoration: $underline;
    color: $sassy-color-pitch-black;
    &:hover {
        color: $sassy-color-pitch-black;
    }
}

@mixin sassy-buyer-link-no-decoration() {
    text-decoration: none;
}

@mixin sassy-buyer-link-touch-color($color) {
    // Touch devices fix for hover state staying active after click/touch
    @media (hover: none) {
        &:hover {
            color: $color;
        }
    }
}

@mixin sassy-buyer-link-touch-fill($color) {
    // Touch devices fix for hover state staying active after click/touch
    @media (hover: none) {
        fill: $color !important; // needs to be !important to override fill: currentColor from dibs vg
        &:hover {
            fill: $color !important; // ^^ same as above
        }
    }
}
