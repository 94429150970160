$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.item {
    padding: 0;
    &.active,
    &:hover {
        background: $sassy-color-buyer-tertiary;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: $sassy-spacing-x-small 0;
    }
}

.link {
    @include sassy-font-body;
    color: $sassy-color-pitch-black;
    cursor: pointer;
    display: inline-block;
    padding: 4px $sassy-spacing-x-small;
    width: 100%;
    text-decoration: none;

    &:hover,
    &:visited {
        color: $sassy-color-noir;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: 0 $sassy-spacing-small;
        @include sassy-font-body($size: large);
    }
}
