$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.section {
    border-bottom: 1px solid $sassy-color-pitch-black;
    padding: $sassy-spacing-small 0;

    &:first-child {
        padding-top: $sassy-spacing-x-small;
    }

    &:last-child {
        border: none;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        border-bottom: 0;
        padding-top: $sassy-spacing-medium;

        &:first-child {
            padding: $sassy-spacing-medium 0 0 0;
        }

        &:last-child {
            .header {
                margin-bottom: $sassy-spacing-x-small;
            }
        }
    }
}

.header {
    @include sassy-font-sub-header();
    margin: 0;
    padding: 4px $sassy-spacing-x-small;
    color: $sassy-color-satan;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-sub-header($type: heavy);
        margin: 0 $sassy-spacing-small;
        padding: 0 0 $sassy-spacing-x-small 0;
        border-bottom: 1px solid $sassy-color-dolphin;
        color: $sassy-color-pitch-black;
    }
}
