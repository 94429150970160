$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_hidden';

@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_variables';
@import '../../scss/shared/_layout';

$skipToContentOffset: 9px;

.wrapper {
    position: relative;

    /*
    This is here because
    1. if the z-index is not set at 0 or above, the marketing banner, which itself has a z-index of -1, "sinks" and becomes unresponsive even when visible
    2. given that this wrapper has a z-index set at all, it must be set to 100 or more to stay above similar items images
    */
    @include sassy-z-index(nav);
    background-color: $sassy-color-white;

    * {
        box-sizing: inherit;
    }

    @media print {
        display: none;
    }
}

.header {
    height: $top-bar-height;
    position: fixed;
    width: 100%;
    // In IE positioned elements generate a new stacking context,
    // starting with a z-index value of 0, causing z-index to work
    // incorrectly. In effect, absolutely positioned element must
    // have a parent positioned element with a greater z-index.
    // Nav drawer has a z-index of -1, so the header must have a
    // z-index of at least 0
    @include sassy-z-index(nav);

    :global(.primary-resp-page-width) & {
        min-width: $dbl-responsive-min-width;
    }

    :global(.primary-legacy-resp-page-width) & {
        min-width: $dbl-legacy-responsive-min-width;
    }

    &.hasBanner {
        position: relative;
        height: $message-banner-height;

        &.isBannerOutOfScreen {
            position: fixed;
            top: -$message-banner-height;
            height: $sticky-top-bar-height;
        }
    }

    &.disableSticky {
        height: $top-bar-height + $bottom-bar-height;
        position: static;

        &.hasBanner {
            margin-bottom: $message-banner-height;
        }
    }

    &.hasTopBarOnly {
        height: $top-bar-height;
    }

    &.hideBottomBar {
        height: $sticky-top-bar-height;
    }
}

.bottomWrapper {
    &.disableSticky {
        position: relative;
        border-bottom: 1px solid $sassy-color-dolphin;
    }
}

.shadow {
    height: $shadow-height-sans-banner;
    position: relative;
    z-index: $shadow-z-index;

    &.hasTopBarOnly {
        height: $shadow-height-only-top-bar;
    }

    &.disableSticky {
        display: none;
    }

    &.isBannerOutOfScreen {
        height: $shadow-height-with-banner;
    }

    &.hideBottomBar {
        height: $sticky-top-bar-height;
    }
}

.bar {
    background: $sassy-color-noir;
    display: flex;
    justify-content: center;
    position: relative;
}

.barContent {
    @include dibs-width;
    align-items: center;
    position: static;
}

.headerLink {
    @include sassy-buyer-contrast-link-light;
}

.skipToContent {
    position: fixed;
    top: $skipToContentOffset;
    left: $skipToContentOffset;
    transition: none !important;
    &:not(:focus) {
        @include sassy-visually-hidden;
    }
    &:focus {
        // make visible only when focused via keyboard
        @include sassy-z-index(overlay);
    }
}
