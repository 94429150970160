$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

:local {
    $input-height-small: 33px;
    $input-height-medium: 40px;
    $input-height-large: 50px;

    .inputContainer {
        position: relative;
        border: thin solid $sassy-color-dolphin;
        display: flex;
        @include sassy-font-body($size: none);
        background: $sassy-color-white;
        box-sizing: border-box;
        transition: border-color 0.15s ease-in;
    }

    .borderError {
        border-color: $sassy-color-alert-primary;
    }

    .borderDark {
        border-color: $sassy-color-pitch-black !important;
    }

    .disabled {
        background: $sassy-color-dolphin !important;
        cursor: not-allowed;
    }

    .borderFocus {
        @if $user-type == 'internal' {
            border-color: $sassy-color-internal-primary;
        } @else if $user-type == 'dealer' {
            border-color: $sassy-color-dealer-primary;
        } @else {
            border-color: $sassy-color-noir;
        }
    }

    .heightLarge {
        height: $input-height-large;
    }

    .heightMedium {
        height: $input-height-medium;
    }

    .heightSmall {
        height: $input-height-small;
    }

    .heightDefault {
        @if $user-type == 'internal' {
            height: $input-height-small;
        } @else if $user-type == 'dealer' {
            height: $input-height-medium;
        } @else {
            height: $input-height-large;
        }
    }

    .noBorder {
        border: none;
    }
}
