$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/_headerMixins';

.wrapper {
    display: flex;
    position: relative;
    height: 100%;
}

.noHover {
    pointer-events: none;
}

.authButtons {
    align-items: center;
    display: flex;
}

.authButton {
    @include sassy-font-body($size: small, $type: none);
    @include sassy-buyer-standard-link($underline: none);
    background: none;
    border: none; // undo button style
    border-left: 1px solid $sassy-color-noir;
    cursor: pointer;
    padding: 0 $sassy-spacing-x-small;

    &:nth-child(1) {
        border: none;
    }
}

.dropdownBodyWrapper {
    @include dropdownBodyWrapperPosition();
}

.dropdown {
    @include dropdownArrowPosition();
}

.dropdownContent {
    padding: $sassy-spacing-small $sassy-spacing-medium;
}

.dropdownLinkWrapper {
    padding: 5px;
    position: relative;
}

.badgeContainer {
    position: absolute;
    right: 0;
}

.dropdownLink {
    @include sassy-buyer-standard-link($underline: none);
    display: flex;
    justify-content: space-between;
}

.unreadCount {
    color: $sassy-color-alert-primary;

    &:hover {
        color: $sassy-color-alert-primary;
    }
}
