@import '~dibs-sassy/exports/_spacing';

$global-nav-height: 432px;
$dbl-tooltip-z-index: 3;

$dbl-width-fallback: calc(100% - (2 * #{$sassy-spacing-large})); // Fallback for IE11
$dbl-width: calc(
    100% - (2 * var(--sassy-layout-offset))
); // --sassy-layout-offset value is set in baseBuyerStyles.scss
$dbl-max-width: 1440px;
$dbl-responsive-min-width: 1024px;
$dbl-legacy-responsive-min-width: 768px;

$loading-gif: 'https://a.1stdibscdn.com/assets/images/interface/processing-white.gif';
$loading-gif-small: 'https://a.1stdibscdn.com/assets/images/interface/processing-white-small.gif';

$no-header-context-z-index: -1;
$nav-utils-wrap-z-index: 4;
$cover-nav-utils-wrap-z-index: $nav-utils-wrap-z-index + 1;
$refine-screen-button-cover: $cover-nav-utils-wrap-z-index + 1;
$registration-credit-z-index: 1;

/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
$sb-price-input-text-z-index: 0;
$sb-price-input-boxes-z-index: $sb-price-input-text-z-index + 1;

$sort-by-dropdown-z-index: 2;

$header-search-z-index: 1;

$facet-nav-item-count-z-index: 0;
$facet-nav-item-action-z-index: $facet-nav-item-count-z-index + 1;

$global-nav-height: 432px;
$global-nav-wrap-z-index: $nav-utils-wrap-z-index - 1;
$header-wrap-z-index: $nav-utils-wrap-z-index;
$tooltip-z-index: $header-wrap-z-index - 1;
$checkout-sidebar-z-index: $header-wrap-z-index - 1;
$checkout-header-wrap-z-index: $checkout-sidebar-z-index + 2;
$checkout-masthead-z-index: $checkout-header-wrap-z-index + 2;

$pdp-sidebar-tooltip-z-index: 1;
$mobile-zoom-modal-header-footer-z-index: 2;

$career-perks-z-index: 1;

$take-tour-modal-arrow-wrapper-z-index: 2;
$storefront-banner-overlay-z-index: 1;

$com-form-mobile-spacing: 15px;
$com-form-input-wrapper-margin-bottom: 1.33em;
$com-form-input-height: 32px;
$com-form-input-tablet-height: 44px;
