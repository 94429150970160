$user-type: 'buyer';

// common variables
@import '../Common/Button/_buttonVariables';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

@mixin buttonColorSecondary($primaryColor) {
    color: $primaryColor;
    background-color: $sassy-color-white;
    border: 2px solid $primaryColor;

    &:hover:not(.disabled) {
        color: $sassy-color-white;
        background-color: $primaryColor;
    }
}

@mixin buttonColorCTA($primaryColor) {
    color: $primaryColor;
    background-color: $sassy-color-white;

    &:hover:not(.disabled) {
        color: $sassy-color-noir;
        background-color: $sassy-color-white;
    }
}

@mixin isDangerousAction($isSecondary, $isTransparent: false) {
    $background: $sassy-color-alert-primary;
    $foreground: $sassy-color-white;
    $background-disabled: $sassy-color-alert-primary;

    @if $isSecondary {
        $background: $sassy-color-white;
        $foreground: $sassy-color-alert-primary;
        $background-disabled: $sassy-color-dolphin;
    }
    @if $isTransparent {
        $background: transparent;
        $foreground: $sassy-color-alert-primary;
        $background-disabled: $sassy-color-dolphin;
    }

    background-color: $background;
    color: $foreground;
    @if $isSecondary or $isTransparent {
        border-color: $foreground;
    }
    &:hover:not(.disabled) {
        background-color: $background-disabled;
        color: $foreground;
        @if $isSecondary or $isTransparent {
            border-color: $foreground;
        }
    }
}
:local {
    .small {
        --button-padding: #{$sassy-spacing-small};
        --button-height: #{$button-size-small};
        --button-line-height: #{$button-size-small};
        @include sassy-font-body($size: x-small, $type: none);
    }
    .medium {
        --button-padding: #{$sassy-spacing-small};
        --button-height: #{$button-size-medium};
        --button-line-height: #{$button-size-medium};
        @include sassy-font-body($size: small, $type: none);
    }
    .large {
        --button-padding: #{$sassy-spacing-large};
        --button-height: #{$button-size-large};
        --button-line-height: #{$button-size-large};
        @include sassy-font-body($size: medium, $type: none);
    }

    // legacy layout functionality -- inline-block with height = line-height
    .wrap,
    .noWrap {
        height: var(--button-height);
        padding: 0 var(--button-padding);
        display: inline-block;
        line-height: var(--button-line-height);
    }

    // :global(.button) increases specificity for these rules while also providing an escape
    // hatch for instances where these rules really, truly must be overridden
    // these styles allow line wrapping when buttons do not have element descendants
    :global(.button).wrap:not(:has(> *)) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1; // unitless line-height is in font-size of element
        white-space: normal;
        // give explicit word-break: normal; to prevent containers from applying word-break to
        // Buttons. for example, Tooltip has a word-break: break-word; rule that applies to child to
        // nested elements.
        word-break: normal;
    }

    .secondaryButton {
        @if $user-type == 'internal' {
            @include buttonColorSecondary($sassy-color-internal-primary);
        } @else if $user-type == 'dealer' {
            @include buttonColorSecondary($sassy-color-dealer-primary);
        } @else {
            @include buttonColorSecondary($sassy-color-noir);
        }
    }

    .textCtaButton {
        @if $user-type == 'internal' {
            @include buttonColorCTA($sassy-color-internal-primary);
        } @else if $user-type == 'dealer' {
            @include buttonColorCTA($sassy-color-dealer-primary);
        } @else {
            @include buttonColorCTA($sassy-color-noir);
        }
    }

    .transparentButton {
        @extend .secondaryButton;
        background-color: transparent;
        &.disabled {
            background-color: transparent;
            color: $sassy-color-dolphin;
            border: 2px solid $sassy-color-dolphin;
        }
    }

    .primaryAltButton {
        color: $sassy-color-white;
        background-color: $sassy-color-noir;
        &:hover {
            background-color: $sassy-color-pitch-black;
        }
    }

    .secondaryAltButton {
        @include buttonColorSecondary($sassy-color-noir);
    }

    .secondaryButton.small,
    .secondaryAltButton.small,
    .transparentButton.small {
        --button-line-height: #{$button-size-small - $button-border-size};
    }

    .secondaryButton.medium,
    .secondaryAltButton.medium,
    .transparentButton.medium {
        --button-line-height: #{$button-size-medium - $button-border-size};
    }

    .secondaryButton.large,
    .secondaryAltButton.large,
    .transparentButton.large {
        // exact offset (double the border) throws vertical center off. This is ok in large buttons.
        --button-line-height: #{$button-size-large - (2 * $button-border-size)};
    }

    .fullWidth {
        width: 100%;
    }

    .isDangerousActionPrimary {
        @include isDangerousAction(false);
    }

    .isDangerousActionSecondary {
        @include isDangerousAction(true);
    }

    .isDangerousActionTransparent {
        @include isDangerousAction(false, true);
    }
}
