$user-type: 'buyer';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_buyerLinks';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';
@import '../../scss/shared/_variables';
@import '../../scss/shared/_layout';

$nav-item-spacing: $sassy-spacing-medium;

.navigation {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
}

.items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.item {
    align-content: center;
    cursor: pointer;
    display: flex;
    &:not(:first-child) > .fullWrapper {
        padding-left: $sassy-spacing-x-small;
    }
    &:not(:last-child) > .fullWrapper {
        padding-right: $sassy-spacing-x-small;
    }
}

.itemLink {
    @include sassy-buyer-standard-link-no-effect($underline: none);
    @include sassy-font-sub-header($type: normal);
    @include responsive-nav-links() {
        @include sassy-font-sub-header($size: small, $type: normal);
    }
    display: inline-block;
    line-height: $bottom-bar-height - 21px;
    position: relative;
    &.pale {
        color: $sassy-color-storm;
    }
}

.linkUnderline {
    position: absolute;
    height: 3px;
    bottom: 0;
    width: 100%;
    left: 0;
    background: $sassy-color-noir;
}

.linkUnderlineTrade {
    composes: linkUnderline;
    background: $sassy-color-noir;
}

.dropdownContent {
    display: flex;
    @include dibs-width;
    margin: 0 auto;
    min-height: $global-nav-height;
}

.dropdownHeader {
    @include sassy-font-sub-header($size: medium, $type: heavy);
    line-height: 24px;
}
.dropdownCol {
    padding: $sassy-spacing-medium 0;
    margin-left: $sassy-spacing-large;
    width: 20%;
    &:first-child {
        margin-left: 0;
    }
    &:last-child.imageModule {
        flex-grow: 1;
        width: 400px;
    }
}
.dropdownLink {
    @include sassy-buyer-link-no-decoration;
    @include sassy-font-body($size: small);
    color: $sassy-color-noir;
    display: block;
    padding-top: $sassy-spacing-x-small;
    &:hover {
        color: $sassy-color-noir;
        text-decoration: underline;
    }
    line-height: 32px;
}

.imageModuleWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    width: 100%;
}

.imageModuleImage {
    height: 100%;
    left: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    opacity: 0;
    transition: opacity 0.5s;
    &.setWidth {
        height: auto;
        width: 100%;
    }
    &.loadedImage {
        opacity: 1;
    }
}

.imageModuleLink {
    display: block;
    height: 300px;
    margin-left: auto;
    width: 400px;
}

.imageBox {
    height: 200px;
    margin-left: auto;
    overflow: hidden;
    position: relative;
    width: 400px;
}

.titleBox {
    background: $sassy-color-white;
    color: $sassy-color-noir;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100px;
    position: absolute;
    right: 0px;
    top: 200px;
    width: 400px;
}

.titleSecondary {
    @include sassy-font-body;
    margin: $sassy-spacing-small $sassy-spacing-small 0;
    width: 100%;
}

.titlePrimary {
    @include sassy-font-header;
    margin: $sassy-spacing-x-small $sassy-spacing-small;
    width: 100%;
}

.dropdownSection {
    margin-top: 20px;

    &:nth-child(1) {
        margin-top: 0;
    }
}
