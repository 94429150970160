$shadow-z-index: -2;
$top-bar-height: 94px;
$top-bar-icons-hover-area-height: 60px;
$sticky-top-bar-height: 64px;
$bottom-bar-height: 41px;
$message-banner-height: 31px;
$mobile-message-banner-height: 76px;
$mobile-message-banner-max-height: 127px;
$shadow-height-with-banner: $top-bar-height + $bottom-bar-height + $message-banner-height;
$shadow-height-sans-banner: $shadow-height-with-banner - $message-banner-height;
$shadow-height-only-top-bar: $top-bar-height;
$search-overlay-spacing: 20px;
$search-section-spacing: 11px;
