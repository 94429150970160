$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

:local {
    $placeholder-animated-top: 8px;

    $transition: 0.1s ease;

    .animatedPlaceholderContainer {
        flex-grow: 1;
        position: relative;
    }

    .animatedPlaceholderStart {
        @include sassy-font-body;
        color: $sassy-color-satan;
        left: 1px; // 1px off from 9px rule because small font for animated looks off
        pointer-events: none;
        position: absolute;
        line-height: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        top: 0px;
        transition: font-size $transition, line-height $transition, top $transition;
        // explicit 'z-index' needed to ensure placeholder renders above input/children
        // on z-axis, otherwise stack order is determined by natural ordering and
        // 'position' values, and may change depending on sibling 'position' values.
        @include sassy-z-index(bump);
    }

    .animatedPlaceholderEnd {
        @include sassy-font-body($type: none, $size: x-small);
        display: block;
        top: $placeholder-animated-top;
        line-height: 12px;
    }

    .marginLeftMedium {
        margin-left: $sassy-spacing-small;
    }

    .marginLeftSmall {
        margin-left: $sassy-spacing-x-small;
    }

    .marginRightMedium {
        margin-right: $sassy-spacing-small;
    }

    .marginRightSmall {
        margin-right: $sassy-spacing-x-small;
    }

    .placeholderPaddingLeftMedium {
        padding-left: $sassy-spacing-small;
    }

    .placeholderPaddingLeftSmall {
        padding-left: $sassy-spacing-x-small;
    }
}
