$user-type: 'buyer';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';

.iconWrapper {
    align-items: center;
    display: flex;
}

.noHover {
    pointer-events: none;
}

.icon {
    cursor: pointer;
    margin: 0 $sassy-spacing-x-small;
    padding-top: $sassy-spacing-x-small;
    position: relative;
    width: 18px;
    bottom: 1px;
    @include headerLink;
}

.countWrapper {
    @include sassy-font-body($size: x-small, $type: none);
    position: absolute;
    width: 100%;
}

.count {
    @include sassy-font-body($size: xx-small);
    position: relative;
    bottom: 3px;
    left: 16px;
    background-color: $sassy-color-pitch-black;
    color: $sassy-color-white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
}

.maxCount {
    padding-left: 3px;
}

.emptyBag {
    font-size: 14px;
    padding: $sassy-spacing-small;
    text-align: center;
}

.dropdownBodyWrapper {
    @include dropdownBodyWrapperPosition();
}

.dropdown {
    @include dropdownArrowPosition();
}

.dropdownTotalItemsRow {
    @include sassy-font-sub-header($size: small, $type: normal);
    border-bottom: 1px solid $sassy-color-dolphin;
    padding: $sassy-spacing-x-small $sassy-spacing-small;
}

.dropdownItemRow {
    cursor: pointer;
    width: 100%;
    display: flex;
    text-align: left;
    text-decoration: none;
    border-bottom: 1px solid $sassy-color-dolphin;
    padding: $sassy-spacing-small 0;
    transition: background 0.4s ease;
    @include sassy-font-body($size: small);

    &:hover {
        background: $sassy-color-moonstone;
    }
}

.dropdownRowLink {
    color: $sassy-color-noir;
}

.dropdownItemColumn {
    padding: 0 $sassy-spacing-small;
}

.dropdownItemImageWrapper {
    height: 75px;
    width: 75px;
    position: relative;
}

.dropdownItemImage {
    margin: auto;
    max-width: 75px;
    max-height: 75px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.dropdownItemTitle {
    > a {
        @include sassy-buyer-standard-link($underline: none);
    }
}

.alert {
    color: $sassy-color-alert-secondary;
}
