$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';

:local {
    .errorMessage {
        color: $sassy-color-alert-primary;
        @include sassy-font-body($size: small);
        margin-top: $sassy-spacing-x-small;
        width: 100%;
        &.showErrorAsNote {
            color: $sassy-color-noir;
        }
    }
    .errorMessageTransition {
        opacity: 0;
        transition: opacity 0.15s ease-in;
        animation: fadeIn 0.15s ease-in;
        &.activeErrorMessageTransition {
            opacity: 1;
        }
    }
    .errorMessageRight {
        text-align: right;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
