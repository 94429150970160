$user-type: 'buyer';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/_alerts';
@import '../../scss/shared/_headerVariables';
@import '../../scss/shared/_headerMixins';

$iconDimension: 18px;

.noHover {
    pointer-events: none;
}
.icon {
    margin: 0 $sassy-spacing-x-small;
    width: $iconDimension;
    position: relative;
    // unset button style
    background: none;
    border: none;
    padding: 0;
    display: grid; // necessary to correctly display svg on safari (flexbox hides svg)
    @include headerLink;
}

.hasUnread {
    @include alert-unread(-4px);
}

.contentContainer {
    @include sassy-font-body($size: small);
    color: $sassy-color-noir;
}

.emptyContainer {
    padding: $sassy-spacing-small;
}
.actionLink {
    text-decoration: initial;
}
.feedList {
    list-style: none;
    margin: 0;
    padding: 0;
}
.feedListItem {
    margin: 0;
    padding: 0;
}
.row {
    cursor: pointer;
    outline-offset: -5px; // prevent outline from disappearing in overflow-hidden container
    display: flex;
    padding: $sassy-spacing-small;
    border-bottom: 1px solid $sassy-color-dolphin;
    transition: background 0.4s ease;
    text-decoration: none; // prevent default link behavior
    &:hover,
    &:focus {
        color: unset; // prevent default link hover colors
        background-color: $sassy-color-moonstone;
    }
}

.thumbnail {
    width: 50px;
    height: 50px;
    align-self: center;
}

.thumbnailImage {
    max-width: 100%;
    max-height: 50px;
}

.details {
    display: flex;
    width: 80%;
    padding-left: $sassy-spacing-small;
}

.detailsNoThumbnail {
    composes: details;
    padding-left: 0;
}

.detailsSecondary {
    font-size: 13px;
}

.detailsLeft {
    width: 75%;
}

.detailsRight {
    align-items: flex-end;
    color: $sassy-color-noir;
    text-align: right;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: $sassy-spacing-x-small;
}

.detailsSubject {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.detailsSubject,
.rewardAmount {
    @include sassy-font-body($size: none, $type: heavy);
}

.rewardsSubject {
    @include sassy-font-body($size: none);
}

.spinnerContainer {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.spinnerWrapper {
    margin-right: $sassy-spacing-medium;
    margin-bottom: $sassy-spacing-medium;
}

.emailIcon {
    width: 16px;
}

.arrowIcon {
    color: $sassy-color-buyer-secondary;
    width: 9px;
    height: 9px;
    display: inline-block;
    position: relative;
    margin-left: $sassy-spacing-x-small;
}

.hideOverlay {
    display: none;
}

.activityFeedContainer .row:last-child {
    border-bottom: none;
}

.rewardsIconWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.rewardsIconContainer {
    height: 50px;
    width: 50px;
}

.rewardsIconLabel {
    @include sassy-font-header($size: none, $type: italic);
    font-size: 9px;
    letter-spacing: -0.5px;
    position: relative;
    transform: rotate(-5deg);
    left: $sassy-spacing-small;
    top: 16px;
}

.verticalOffset1 {
    position: relative;
    bottom: 1px;
}

.verticalOffset2 {
    position: relative;
    bottom: 2px;
}

.dropdownBodyWrapper {
    @include dropdownBodyWrapperPosition();
}

.dropdown {
    @include dropdownArrowPosition();
}

.dropdownFooter {
    padding: $sassy-spacing-x-small;
    text-align: center;
}

.rewardsLastChild {
    border-bottom: none;
}

.code {
    @include sassy-font-body($size: none, $type: heavy);
    margin: 0 5px;
}

.promoDetail {
    width: 125%;
}

.promoIconWrapper {
    color: $sassy-color-buyer-primary;
    width: 50px;
    height: 50px;
    display: inline-block;
    position: relative;
    justify-content: center;
}

.promoIconImg {
    height: 100%;
    width: 100%;
}

.notification {
    position: relative;
    padding: $sassy-spacing-small;
    display: block;
    text-decoration: none; // prevent default link behavior

    &:not(:last-child) {
        border-bottom: 1px solid $sassy-color-dolphin;
    }

    &:hover {
        color: unset; // prevent default link hover colors
        background: $sassy-color-moonstone;
    }

    &.unread {
        background: $sassy-color-moonstone;

        &::before {
            background: $sassy-color-buyer-primary;
            content: '';
            position: absolute;
            top: 50%;
            left: 7px;
            border-radius: 50%;
            width: 7px;
            height: 7px;
        }
    }
}

.heading {
    display: flex;
    justify-content: space-between;
}

.headingDetail {
    color: $sassy-color-noir;
}

.notificationBody {
    display: flex;
    margin: $sassy-spacing-x-small 0;
}

.arrow {
    color: $sassy-color-buyer-primary;
    width: 12px;
    height: 12px;
    margin-left: $sassy-spacing-small;
    align-self: center;
}

.hidden {
    display: none;
}
