$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-sassy/exports/_focus';

$slider-width: 63px;
$slider-height: 30px;
$dot-size: 22px;
$dot-horizontal-placing: ($slider-height - $dot-size) * 0.5;
$dot-vertical-placing: 5px;
$border-radius: 9999px;
$outline-margin: 2px;
$outline-block-padding: calc(2 * $outline-margin);

$color-switch-primary: $sassy-color-noir;

@if $user-type == 'internal' {
    $color-switch-primary: $sassy-color-internal-primary;
} @else if $user-type == 'dealer' {
    $color-switch-primary: $sassy-color-dealer-primary;
}

.wrapper {
    @include sassy-font-body($size: small);
    position: relative;
    display: flex;

    .switch {
        position: relative;
        cursor: pointer;
        display: inline-block;
        height: $slider-height;
        outline: none;

        .slider {
            width: $slider-width;
            height: $slider-height;
            padding: 0;
            transition: all 0.2s ease;
            border: thin solid $sassy-color-storm;
            border-radius: $border-radius;
            background: $sassy-color-storm;
        }

        &:focus-visible::after {
            content: '';
            position: absolute;
            top: -$outline-margin;
            left: -$outline-margin;
            width: calc(100% + $outline-block-padding);
            height: calc(100% + $outline-block-padding);
            border: 1px solid $sassy-color-noir;
            border-radius: $border-radius;
            @include sassy-z-index(high);
        }

        &.isChecked {
            .slider {
                background: $color-switch-primary;
                border: thin solid $color-switch-primary;

                .sliderOn {
                    opacity: 1;
                    transition: opacity 0.25s ease;
                }

                .sliderOff {
                    opacity: 0;
                }
            }

            .dot {
                left: $slider-width - $dot-size - $dot-vertical-placing;
            }
        }
    }

    &.isDisabled {
        pointer-events: none;

        .switch .slider {
            background-color: $sassy-color-dolphin;
            border: thin solid $sassy-color-dolphin;
        }
    }
}

.label {
    @include sassy-font-body($size: small, $type: none);
    line-height: 1.25;
    margin: auto $sassy-spacing-small auto 0;
    position: relative;
}

.dot {
    width: $dot-size;
    height: $dot-size;
    position: absolute;
    top: $dot-horizontal-placing;
    left: $dot-vertical-placing;
    transition: all 0.25s ease;
    border-radius: $border-radius;
    box-sizing: border-box;
    background: $sassy-color-white;
}

.sliderOn {
    left: $sassy-spacing-xx-small;
    opacity: 0;
}

.sliderOff {
    right: $sassy-spacing-xx-small;
    opacity: 1;
}

.icon {
    position: absolute;
    top: $sassy-spacing-xx-small;
    fill: $sassy-color-white;
    user-select: none;
    transition: opacity 0.25s ease;
}
