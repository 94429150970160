$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.items {
    display: flex;
    align-items: center;
    margin: 0 $sassy-spacing-small;
    max-width: 400px;

    @include sassy-breakpoint-below(tablet-portrait) {
        margin-top: $sassy-spacing-small;
    }
}

.item {
    margin: 0;
    flex: 0 0 20%;
}

.image {
    display: block;
    max-height: 70px;
    max-width: 100%;
    margin: auto;
    padding: $sassy-spacing-x-small;
}
