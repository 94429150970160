@mixin sassy-visually-hidden {
    // for background info, see:
    // https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
    // https://a11yproject.com/posts/how-to-hide-content/
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
